import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

const labelArr = [
  'Title',
  'Select A Store Label',
  'Select A Preferred Date Label',
  'Select A Preferred Time Slot Label',
  'Number Of Guest',
  'Just Me Label',
  'Me And Others',
  'Button Change Schedule Text',
  'Button Cancel Text',
  'Successfully Change Schedule Label',
  'Confirm Your Reschedule Label',
  'See You Soon',
  'Button Appointment Details Text',
  'Select Store And Date Text',
  'Update Failed',
  'Please Enter Area Label',
  'Apply Filters Button Label',
  'Clear Filters Button Label',
  'Filter Stores Label',
  'No Store Satisfying The Selected Filters Label',
  'Area Label',
  'Available Translation Text',
  'Not Available Translation Text',
  'Selected Translation Text'
];

const checkboxValArr = [
  'Checkbox Sccept The Terms',
  'Checkbox Booking Confirmation Via SMS',
  'Checkbox SMS',
  'Checkbox Email',
  'Check Direct Mailing',
  'BackgroundDesktop',
  'BackgroundMobile'
];

export default class ChangeScheduleModel {
  constructor() {
    labelArr.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });

    checkboxValArr.forEach((item) => {
      this[item] = false;
    });

    this['List Store'] = [];

    this['Icon Successfully Change Schedule'] = {};

    this['Button Change Schedule Link'] = new SitecoreLinkModel();

    this['Button Cance Link'] = new SitecoreLinkModel();

    this['Button Appointment Details Link'] = new SitecoreLinkModel();

    this['campaign'] = {};

    this['appointment'] = {};

    this['contact'] = {};
  }

  getData(dataSource) {
    labelArr.map((label) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, label)) {
        this[label] = dataSource[label] ?? '';
      }
    });

    checkboxValArr.map((checkbox) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, checkbox)) {
        this[checkbox] = dataSource[checkbox] ?? false;
      }
    });

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'List Store')) {
      this['List Store'] = dataSource['List Store'];
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Icon Successfully Change Schedule')) {
      this['Icon Successfully Change Schedule'] = dataSource['Icon Successfully Change Schedule'];
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Button Change Schedule Link')) {
      this['Button Change Schedule Link'] = dataSource['Button Change Schedule Link'];
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Button Cance Link')) {
      this['Button Cance Link'] = dataSource['Button Cance Link'];
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Button Appointment Details Link')) {
      this['Button Appointment Details Link'] = dataSource['Button Appointment Details Link'];
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'campaign')) {
      this['campaign'] = dataSource['campaign'];
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'appointment')) {
      this['appointment'] = dataSource['appointment'];
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'contact')) {
      this['contact'] = dataSource['contact'];
    }

    return this;
  }
}
