import './Form.scss';

import axios from 'axios';
import PropTypes from 'prop-types';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

import LoadingMask from '@components/Share/LoadingMask';
import {
  getPhoneNumber,
  getPhoneNumberId,
  getSelectedSelectionValue,
  getSubmitAction,
  resetCaptcha,
  setSubmitButtonStatus
} from '@redux/actions/unsubscribe';
import reviewAppointmentService from '@services/reviewAppointmentService';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Form } from '@sitecore-jss/sitecore-jss-react-forms';

import config from '../../temp/config';
import FormSubmitErrorPopup from '../Share/RichText/FormSubmitErrorPopup';
import { behaviorSubjectCheckboxList } from './checkBoxBehavior';
import { behaviorSubjectSubmitForm$ } from './formBehavior';
import FormDataModel from './FormDataModel';
import customFieldFactory from './SitecoreForm/CustomFieldFactory';
import FormFieldAllError from './SitecoreForm/FormFieldAllError';

const SubscribeForm = (props) => {
  const dispatch = useDispatch();
  const [checkBoxList, setCheckBoxList] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [appointmentId, setAppointmentId] = useState(null);
  const pageName = SitecoreContextFactoryService.getValueContextRouteItem('displayName');
  const reviewExperiencePageName = 'review experience';
  const [layoutData, setLayoutData] = useState(null);
  const hideAfterSubmitSuccess = props.fields.metadata.hideFormAfterSubmit;
  const [isShowFormSubmitError, setIsShowFormSubmitError] = useState(false);

  useEffect(() => {
    const model = new FormDataModel();

    setLayoutData(model.getData(props.fields));
  }, []);

  useEffect(() => {
    const behaviorSubjectCheckboxListSubscription = behaviorSubjectCheckboxList.subscribe((params) => {
      setCheckBoxList(params);
    });

    return () => {
      behaviorSubjectCheckboxListSubscription.unsubscribe();
    };
  }, [behaviorSubjectCheckboxList]);

  useEffect(() => {
    if (props.fields.metadata?.cssClass === 'franchise-enquiry') {
      dispatch(
        getPhoneNumberId(
          props.fields.fields.find((item) => {
            return item.model.name === 'PhoneNumber';
          })
        )
      );
    }
  }, []);

  useEffect(() => {
    dispatch(
      getPhoneNumber({
        itemValue: '',
        value: ''
      })
    );

    return () => {
      dispatch(getSubmitAction(false));
    };
  }, []);

  useEffect(() => {
    const url = window.location.search;
    const urlParam = new URLSearchParams(url);

    setAppointmentId(urlParam.get('appointment_id') || '');
  }, [appointmentId]);

  const formFetcher = (formData, endpoint) => {
    dispatch(setSubmitButtonStatus(true));

    /** INFO: hide popup for everytime start fetch form */
    setIsShowFormSubmitError(false);

    formData.data.forEach((item) => {
      if (props.captchaObj[item.key]) {
        item.value = props.captchaObj[item.key].value;
      }
      if (
        item.key ===
        Object.keys(props.selectedSelectionValue).find((id) => {
          return id === item.key;
        })
      ) {
        item.value = props.selectedSelectionValue[item.key]?.value?.label || '';
      }
      if (!checkBoxList.status && item?.key === checkBoxList.field) {
        item.value = '';
      }
    });

    // INFO: create new request body data using FormData
    // eslint-disable-next-line no-undef
    const customFormData = new FormData();
    for (const dataItem of formData.data) {
      customFormData.append(dataItem.key, dataItem.value);
    }

    return axios
      .post(endpoint, customFormData, {
        withCredentials: true
      })
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.errors.length) {
            // Handle check page is Review Appointment Page
            if (pageName.toLowerCase() === reviewExperiencePageName) {
              reviewAppointmentService({ appointmentId: appointmentId }).subscribe((res) => {
                if (res.StatusCode === 0 || res.StatusCode === 200) {
                  handleCheckStatusSubmitForm(res, formData);
                } else {
                  dispatch(getSubmitAction(false));

                  behaviorSubjectSubmitForm$.next({ isSuccess: false, formItemId: props.fields.formItemId.id });
                }
              });
            } else {
              handleCheckStatusSubmitForm(response, formData);
            }
          } else {
            dispatch(getSubmitAction(false));

            behaviorSubjectSubmitForm$.next({ isSuccess: false, formItemId: props.fields.formItemId.id });
          }

          return response.data;
        }
      })
      .catch((error) => {
        if (error?.response?.data) {
          dispatch(resetCaptcha(false));

          dispatch(getSubmitAction(false));

          formData.data.forEach((item) => {
            if (props.captchaObj[item.key]) {
              window.grecaptcha.reset(props.captchaObj[item.key].widgetId);
            }
          });

          return error.response.data;
        }
      })
      .finally(() => {
        dispatch(setSubmitButtonStatus(false));
      });
  };

  const handleCheckStatusSubmitForm = (res, formData) => {
    behaviorSubjectSubmitForm$.next({ isSuccess: true, formItemId: props.fields.formItemId.id, label: false });

    if (!res.data?.redirectUrl?.length || !res.data?.redirectUrl) {
      dispatch(getSubmitAction(false));

      setIsShowFormSubmitError(true);

      setSubmitted(hideAfterSubmitSuccess);

      if (hideAfterSubmitSuccess) {
        const successMsg = document.getElementsByClassName('form-message-success')[0];

        successMsg.scrollIntoView({
          behavior: 'auto',
          block: 'center',
          inline: 'center'
        });
      }
    } else window.location.href = res.data.redirectUrl;

    dispatch(resetCaptcha(false));

    formData.data.forEach((item) => {
      if (props.captchaObj[item.key]) {
        window.grecaptcha.reset(props.captchaObj[item.key].widgetId);
      }
      if (
        item.key ===
        Object.keys(props.selectedSelectionValue).find((id) => {
          return id === item.key;
        })
      ) {
        dispatch(
          getSelectedSelectionValue({
            [item.key]: {
              ...props.selectedSelectionValue[item.key],
              value: null
            }
          })
        );
      }
      if (item.key === props.phoneNumber.itemValue) {
        item.value = props.phoneNumber.value || '';

        dispatch(
          getPhoneNumber({
            itemValue: item.key || '',
            value: ''
          })
        );
      }
    });
  };

  const handleCloseErrorPopup = useCallback(() => setIsShowFormSubmitError(false), []);

  return props?.fields ? (
    <Fragment>
      <div className='signup-form animated-slow fadeIn delay-100'>
        <div className='container'>
          <div className={`signup-form__form ${props.fields.metadata?.cssClass} ${hideAfterSubmitSuccess && submitted ? 'hide' : ''}`}>
            {
              layoutData ? (
                <Form
                  form={layoutData}
                  sitecoreApiHost={config.sitecoreApiHost}
                  sitecoreApiKey={config.sitecoreApiKey}
                  onRedirect={(url) => {
                    props.history.push(url);
  
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  }}
                  errorComponent={FormFieldAllError}
                  fieldFactory={customFieldFactory}
                  formFetcher={formFetcher}
                // fieldWrapperComponent={WrapperComponent}
                />
              ) : (
                ''
              )
            }
          </div>
        </div>
      </div>
      {!props.isLoading ? <LoadingMask></LoadingMask> : null}
      {isShowFormSubmitError ? <FormSubmitErrorPopup onClose={handleCloseErrorPopup} /> : <></>}
    </Fragment>
  ) : (
    ''
  );
};

SubscribeForm.propTypes = {
  fields: PropTypes.object,
  history: PropTypes.any,
  children: PropTypes.any,
  field: PropTypes.object,
  captchaObj: PropTypes.object,
  selectedSelectionValue: PropTypes.object,
  phoneNumber: PropTypes.any,
  isLoading: PropTypes.bool
};

const mapStateToProps = (state) => ({
  captchaObj: state.unsubscribeReducer.captchaObj || {},
  selectedSelectionValue: state.unsubscribeReducer.selectedSelectionValue || {},
  phoneNumber: state.unsubscribeReducer.phoneNumber || '',
  isLoading: state.unsubscribeReducer.isLoading || false
});

export default connect(mapStateToProps)(withRouter(SubscribeForm));
