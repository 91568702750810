import { useFormik } from 'formik';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Yup } from '@components/SingleSignOn/RegisterLogin/Register/utils';
import { getUserFullNameWithoutFormat } from '@components/SlicingPage/MyAccount/utils';
import { checkCommerceUser } from '@redux/actions/checkoutProcess';
import global from '@utils/global';

import { useCampaignContext } from '../../useCampaignContext';
import { useGetOtp } from '../useGetOtp';

const useRegisterUserInfoFormik = (campaignId, layoutData) => {
  const dispatch = useDispatch();
  const accountUserInfo = useSelector((state) => state.singleSignOnReducer.userInfoReducer?.accountUser);
  const settingGlobal = useSelector((state) => state.settingGlobalReducer.settingGlobal);
  const { setUserRegisterInfo } = useCampaignContext();
  const getOtpService = useGetOtp();

  const useAreaCode = useMemo(() => {
    return settingGlobal?.UseAreaCode;
  }, [settingGlobal]);

  const areaCodes = useMemo(() => {
    return settingGlobal?.AreaCodes || [];
  }, [settingGlobal]);

  const mobileNumberConvert = useMemo(() => {
    let defaultValue = accountUserInfo?.PhoneNumber || '';
    const defaultPrefixCode = useAreaCode && areaCodes.length ? areaCodes[0].value : '';
    const phoneFormat = global.splitPhoneNumberWithoutCode(useAreaCode, defaultValue, areaCodes);

    return {
      prefix: phoneFormat.code || defaultPrefixCode,
      mobileNumber: phoneFormat.value
    };
  }, [accountUserInfo, areaCodes, useAreaCode]);

  const initialValues = useMemo(
    () => {
      const firstName = accountUserInfo?.FirstName || '';
      const lastName = accountUserInfo?.LastName || '';

      return ({
        firstName: getUserFullNameWithoutFormat({ firstName, lastName }),
        // lastName: accountUserInfo?.LastName || '',
        email: accountUserInfo?.Email || '',
        dob: accountUserInfo?.DoB || '',
        phonePrefix: mobileNumberConvert.prefix || '',
        phoneNumber: mobileNumberConvert.mobileNumber || ''
      });
    },
    [accountUserInfo]
  );

  return useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      firstName: Yup.string()
        .required(layoutData?.['inputValidFirstNameMessage']?.value)
        .matches(global.nameValidateRegex(), layoutData?.['inputValidFirstNameMessage']?.value)
        .trim(),
      // lastName: Yup.string()
      //   .required(layoutData?.['inputValidLastNameMessage']?.value)
      //   .matches(global.nameValidateRegex(), layoutData?.['inputValidLastNameMessage']?.value)
      //   .trim(),
      email: Yup.string()
        .notRequired()
        .test('email_check', layoutData?.['inputValidEmailAddressMessage']?.value, (value) => {
          if (value) {
            return Yup.string().email().isValidSync(value);
          }

          return true;
        }),
      dob: Yup.string()
        .notRequired()
        .test('date_check', layoutData?.['inputValidBirthDateMessage']?.value, (value) => {
          if (value) {
            return Yup.date().isValidSync(value);
          }

          return true;
        }),
      phoneNumber: Yup.string()
        .required(layoutData?.['inputValidMobileNumberMessage']?.value)
        .trim()
        .validatePhone({
          min: settingGlobal?.PhoneNumberMinimumMust || settingGlobal?.PhoneNumberMust,
          max: settingGlobal?.PhoneNumberMust,
          internalPrefixKey: 'phonePrefix',
          errorMsg: layoutData?.['inputValidMobileNumberMessage']?.value
        })
    }),
    onSubmit: (values) => {
      // if (values.firstName && values.lastName && values.phoneNumber) {
      if (values.firstName && values.phoneNumber) {
        const phoneNumberValue = `${values.phonePrefix}${values.phoneNumber}`;

        setUserRegisterInfo({
          firstName: values.firstName,
          // lastName: values.lastName,
          email: values.email,
          dob: values.dob,
          phoneNumber: phoneNumberValue
        });
        if (!accountUserInfo && values.email) {
          dispatch(checkCommerceUser(values.email));
        } else {
          const params = {
            campaignId: campaignId,
            phoneNumber: phoneNumberValue
          };

          getOtpService(params);
        }
      }
    }
  });
};

export default useRegisterUserInfoFormik;
